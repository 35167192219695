.loader_parent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    opacity: .5;
  }