.kkk {
    cursor: pointer;
    opacity: .5;
}

.kkk:hover {
    opacity: .8;
}

.kkk:active {
    opacity: 1;
}

.table td {
    vertical-align: middle;
    font-size: .8em;
}

.add_instr_btn_save {
    margin-left: 20px;
}

.ggg {
    width: 100%;
}

.input_read {
    width: 100%;
}


.input_read:active,:hover,:focus{
    outline:0;outline-offset:0
}